<template>
  <div>
    <div
      v-if="exportType != 'simple'"
      class="download-box download-select load"
      :class="{
        load: exportDisabled || disabled
      }"
    >
      <b-dropdown
        size="sm"
        variant="primary"
        class="download"
        :disabled="exportDisabled || disabled"
        :text="exportText"
      >
        <b-dropdown-item @click="expor('all')"
          >Filter Results ({{ totalItems }})
        </b-dropdown-item>
        <b-dropdown-item @click="expor()"
          >Selected ({{ saveData.length }})</b-dropdown-item
        >
      </b-dropdown>
      <b-icon
        v-if="exportDisabled || disabled"
        class="counterclockwise"
        icon="arrow-counterclockwise"
        animation="spin-reverse"
        font-scale="1.2"
      ></b-icon>
    </div>
    <b-button
      v-else
      variant="primary"
      class="mr-2"
      @click="expor('allSimple')"
      size="sm"
      :disabled="exportDisabled || disabled"
      >{{ exportText }}
      <b-icon
        v-if="disabled"
        class="counterclockwise"
        icon="arrow-counterclockwise"
        animation="spin-reverse"
        font-scale="1.2"
      ></b-icon>
      <b-icon
        v-else
        class="counterclockwise"
        :icon="exportIcon ? exportIcon : 'arrow-counterclockwise'"
        font-scale="1.2"
      >
      </b-icon>
    </b-button>
  </div>
</template>

<script>
import JwtService from '@/common/services/jwt';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'ExportReservation',
  data() {
    return {
      disabled: false
    };
  },
  props: {
    exportType: String,
    exportText: String,
    exportUrl: String,
    exportDisabled: Boolean,
    fileName: String,
    saveData: Array,
    exportIcon: String,
    totalItems: Number
  },
  components: {},
  methods: {
    /**
     * param 将要转为URL参数字符串的对象
     * key URL参数字符串的前缀
     * encode true/false 是否进行URL编码,默认为true
     *
     * return URL参数字符串
     */
    urlEncode(param, key, encode) {
      if (param == null) return '';
      var paramStr = '';
      var t = typeof param;
      if (t == 'string' || t == 'number' || t == 'boolean') {
        if (key != 'limit' && key != 'page' && key != 'tab_type') {
          paramStr +=
            '&' +
            key +
            '=' +
            (encode == null || encode ? encodeURIComponent(param) : param);
        }
      } else {
        for (var i in param) {
          var k =
            key == null
              ? i
              : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
          paramStr += this.urlEncode(param[i], k, encode);
        }
      }
      return paramStr;
    },
    expor: function(state) {
      this.disabled = true;
      let url, urlid;
      if (state == 'all') {
        url = this.exportUrl + '?token=' + JwtService.getToken();
      } else if (state == 'allSimple') {
        url = this.exportUrl + '&token=' + JwtService.getToken();
      } else {
        urlid = {
          purchase_invoice_id: this.saveData
        };
        console.log(this.urlEncode(urlid));
        url = `${this.exportUrl}?token=${JwtService.getToken()}${this.urlEncode(
          urlid
        )}`;
      }
      // eslint-disable-next-line no-useless-escape
      url = url.replace(/\n|\r/g, '').replace(/\ +/g, '');
      console.log(url);
      var xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      var vthis = this;
      xhr.onload = function() {
        console.log(vthis);
        if (this.status === 200) {
          var blob = this.response;
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = function(e) {
            const link = document.createElement('a');
            link.style.display = 'none';
            link.href = e.target.result;
            let num = '';
            for (let i = 0; i < 10; i++) {
              num += Math.ceil(Math.random() * 10);
            }
            console.log(num);
            // zip
            link.setAttribute('download', `${vthis.fileName}.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          };
          console.log('下载完了');
          vthis.disabled = false;
          vthis.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Download Successful.' // 提示内容
          });
        } else {
          vthis.disabled = false;
          vthis.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Please choose the same supplier name.' // 提示内容
          });
        }
      };
      xhr.send();
    }
  },

  computed: {},
  mounted() {
    // console.log(JwtService.getToken());
  }
};
</script>
<style scoped></style>
