<template>
  <div>
    <TAdvancedSearch ref="TAdvancedSearch">
      <template v-slot:advanced-search>
        <Advanced
          v-if="tableSubmit != null"
          :searchConfiguration.sync="searchConfiguration"
          :searchObj.sync="tableSubmit"
          :tableId.sync="tableData.tabId"
        ></Advanced>
      </template>
    </TAdvancedSearch>

    <KTPortlet>
      <template v-slot:body>
        <b-overlay :show="loading" class="head-loading">
          <div class="operate-box">
            <UploadMore
              :input="upload"
              :accept="accept"
              ref="UploadMore"
            ></UploadMore>

            <ExportPDF
              v-b-popover.hover.topleft=""
              :exportText="'Download Invoice'"
              :exportUrl="exportPDFUrl"
              :exportDisabled="exportPDFDisabled"
              exportType="simple"
              exportIcon="box-arrow-in-down"
              fileName="Purchase Invoice"
            ></ExportPDF>

            <ImportMore
              :input="importInvoice"
              :accept="Importaccept"
              ref="ImportMore"
            ></ImportMore>
          </div>
          <CustomTable
            v-if="tableSubmit != null"
            :itemsFn="itemsFn"
            :tableData="tableData"
            :sortName="sort_name"
            :tableObj="tableSubmit"
            :tableId.sync="tableData.tabId"
            :checkboxChange="checkboxChange"
            :checkboxAll="checkboxAll"
          ></CustomTable>
        </b-overlay>
      </template>
    </KTPortlet>
  </div>
</template>

<script>
import TAdvancedSearch from '@/components/TAdvancedSearch/Index.vue';
import KTPortlet from '@/views/content/Portlet';
import Advanced from '@/components/Search/Ordinary.vue';
import CustomTable from '@/components/Table/Index.vue';
import ExportPDF from '@/components/Export/PDF.vue';
import UploadMore from '@/components/UploadMore/Index.vue';
import ImportMore from '@/components/ImportMore/Index.vue';
import { RENEW_BASE_CONFIG } from '@/store/modules/baseconfig/actions';
import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
import apiBus from '@/common/apiBus/index';
import { mapState, mapGetters } from 'vuex';
import JwtService from '@/common/services/jwt';

export default {
  name: 'SaaSPuchaseInvoiceFile',
  components: {
    TAdvancedSearch,
    KTPortlet,
    Advanced,
    CustomTable,
    ExportPDF,
    UploadMore,
    ImportMore
  },
  data() {
    return {
      // 高级搜索配置
      loading: false,
      exportPDFUrl: '/rest/api/purchase/download-purchase-invoice-pdf',
      exportPDFDisabled: false,
      exportErrorText: '',
      accept: 'application/pdf',
      Importaccept: '.xls,.xlsx',
      searchConfiguration: {
        status: {
          type: 'checked-all',
          class: 'text_color_all',
          text: 'Status',
          allSelected: true,
          md: 6,
          resetVal: [0, 1, 2],
          options: [
            { label: 'New', value: 0, class: 'text_yellow' },
            { label: 'Processing', value: 1, class: 'text_blue' },
            { label: 'Completed', value: 2, class: 'text_green' }
          ]
        },
        vat_refund: {
          type: 'checked-all',
          class: 'text_color_all',
          text: 'VAT Refund',
          allSelected: false,
          md: 6,
          resetVal: [],
          options: [
            { label: 'Yes', value: 1, class: 'text_green_dashed' },
            { label: 'No', value: 2, class: 'text_red_dashed' },
            { label: 'Uncertain', value: 3, class: 'text_grey_dashed' }
          ]
        },
        purchase_id: {
          type: 'input',
          text: 'Purchase ID',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        upload_date: {
          type: 'datepicker_from_to',
          md: '6',
          text: 'Upload Date',
          limit: {
            // start: null,
            end: 0
          },
          from: {
            placeholder: 'From'
            // resetVal: this.format(new Date().setDate(new Date().getDate() - 7))
          },
          to: {
            placeholder: 'To'
            // resetVal: this.format(new Date())
          }
        },
        order_id: {
          type: 'input',
          text: 'Order ID',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        },
        invoice_number: {
          type: 'input',
          text: 'Invoice Number',
          input_type: 'text',
          placeholder: this.$t('page.please_choose_input')
        }
      },
      tableData: {
        fields: [
          {
            key: 'upload_date',
            label: 'Upload Date'
          },
          {
            key: 'file_name',
            label: 'File Name'
          },
          {
            key: 'invoice_no_text',
            label: 'Invoice No.'
          },
          {
            key: 'vat_refund',
            label: 'VAT Refund'
          },
          {
            key: 'order_id_arr',
            label: ' Order ID'
          },
          {
            key: 'purchase_id_arr',
            label: 'Purchase ID'
          },
          {
            key: 'status',
            label: 'Status'
          },
          {
            key: 'action',
            label: ''
          }
        ],
        tabId: 'saas_purchase_invoice_file',
        rowClass: '',
        class: 'new_table',
        items: [],
        status: null,
        totalItems: 9999999999999,
        pageOptions: [10, 30, 50, 100, 200]
      },
      // 搜索提交数据
      tableSubmit: null,
      initialTableSubmit: {
        status: [0, 1, 2],
        vat_refund: [],
        purchase_id: null,
        upload_date_to: null,
        upload_date_from: null,
        order_id: null,
        invoice_number: null,
        limit: 100,
        page: 1
      },
      sort_name: '',

      // 表格数据
      save_data: [],
      save_data_id: [],
      tiemID: null,
      options: {
        sales_channel: [],
        supplier_list: []
      }
    };
  },

  methods: {
    /**
     * param 将要转为URL参数字符串的对象
     * key URL参数字符串的前缀
     * encode true/false 是否进行URL编码,默认为true
     *
     * return URL参数字符串
     */
    urlEncode(param, key, encode) {
      if (param == null) return '';
      var paramStr = '';
      var t = typeof param;
      if (t == 'string' || t == 'number' || t == 'boolean') {
        if (key != 'limit' && key != 'page' && key != 'tab_type') {
          paramStr +=
            '&' +
            key +
            '=' +
            (encode == null || encode ? encodeURIComponent(param) : param);
        }
      } else {
        for (var i in param) {
          var k =
            key == null
              ? i
              : key + (param instanceof Array ? '[' + i + ']' : '.' + i);
          paramStr += this.urlEncode(param[i], k, encode);
        }
      }
      return paramStr;
    },

    upload(e) {
      this.$refs.UploadMore.state = 'uploading';
      let fileData = new FormData();
      e.forEach((item, i) => {
        fileData.append(`file[${i}]`, item);
      });
      apiBus.saasPuchaseInvoiceFile
        .uploadPurchaseInvoice(fileData)
        .then((data) => {
          console.log(data);
          this.$refs.UploadMore.state = 'successfully';
          this.$root.$emit('bv::refresh::table', this.tableData.tabId);
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Uploaded Successfully!' // 提示内容
          });
        })
        .catch((error) => {
          console.log(error);
          this.$refs.UploadMore.state = 'error';
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    importInvoice(e) {
      this.$refs.ImportMore.state = 'uploading';
      let fileData = new FormData();
      fileData.append('file', e[0]);
      apiBus.saasPuchaseInvoiceFile
        .importPurchaseInvoice(fileData)
        .then((data) => {
          console.log(data);
          this.$refs.ImportMore.state = 'successfully';
          this.$root.$emit('bv::refresh::table', this.tableData.tabId);
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'success', // 提示状态 success=>成功 danger=> 报错
            alertText: 'Uploaded Successfully!' // 提示内容
          });
        })
        .catch((error) => {
          console.log(error);
          this.$refs.ImportMore.state = 'error';
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
        });
    },
    searchTableData(data) {
      var tableData = [];
      data.forEach((key) => {
        let {
          upload_date,
          file_name,
          invoice_no,
          vat_refund,
          status,
          order_id,
          purchase_id,
          id
        } = key;

        tableData.push({
          id,
          upload_date: upload_date,
          file_name: file_name,
          invoice_no_text: invoice_no,
          vat_refund: vat_refund,
          status: this.searchConfiguration.status.options[status].label,
          status_id: status,
          order_id_arr: order_id,
          purchase_id_arr: purchase_id,
          openFn: this.uploadcheck,
          action: 'SaaSPuchaseInvoiceFile'
        });
      });
      return tableData;
    },
    itemsFn() {
      this.CACHE.addCache(
        this.currentUser.uid,
        'SaaSPuchaseInvoiceFile',
        this.tableSubmit
      );
      this.loading = true;
      this.save_data = [];
      return apiBus.saasPuchaseInvoiceFile
        .purchaseInvoiceList(this.tableSubmit)
        .then((data) => {
          // console.log(data);
          this.loading = false;
          this.tableData.items = this.searchTableData(data.data.data.list);
          this.tableData.totalItems = data.data.data.total;
          this.tableData.status = data.data.message;
          // this.exportUrlFn(this.tableSubmit);
          this.exportUrlFn(this.tableSubmit);
          return this.tableData.items;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$store.dispatch(PROMPT_STATUS, {
            dismissCountDown: 5, //初始提示作用于定时器
            alert_variant: 'danger', // 提示状态 success=>成功 danger=> 报错
            alertText:
              error.data == undefined ? error.message : error.data.message // 提示内容
          });
          this.tableData.totalItems = 0;
          return [];
        });
    },
    ifAllCheckbox() {
      var checkboxLength = [],
        ifCheckboxL = [];
      this.tableData.items.forEach((itme) => {
        if (itme.select) {
          checkboxLength.push(itme.select);
        }
        if (itme.selectDisabled != 'disabled') {
          ifCheckboxL.push(itme.select);
        }
      });

      if (
        checkboxLength.length == ifCheckboxL.length &&
        ifCheckboxL.length > 0
      ) {
        this.tableData.fields[0].allSelect = true;
      } else {
        this.tableData.fields[0].allSelect = false;
      }
    },
    checkboxChange(select, index) {
      var splice_index = this.save_data.indexOf(index);
      // console.log(index);
      if (select) {
        this.save_data.push(index);
      } else {
        this.save_data.splice(splice_index, 1);
      }

      this.ifAllCheckbox();
    },
    checkboxAll(select) {
      if (select) {
        this.tableData.items.forEach((item) => {
          item.select = true;
        });
      } else {
        this.tableData.items.forEach((item) => {
          item.select = false;
        });
      }
    },
    exportUrlFn(data) {
      this.exportPDFUrl = '/rest/api/purchase/download-purchase-invoice-pdf';
      this.exportPDFUrl = this.exportPDFUrl + `?${this.urlEncode(data)}`;
      console.log(this.exportPDFUrl);
    },

    uploadcheck(id) {
      var childWin = window.open(
        `/rest/api/purchase/print-purchase-invoice?purchase_invoice_file_id=${id}&token=${JwtService.getToken()}`,
        'newwindow',
        'height=700, width=600, top=10, left=10, toolbar=no, menubar=no, scrollbars=no, resizable=no,location=no, status=no'
      );
      childWin.onload = function () {
        childWin.print();
      };
    },
    add0(m) {
      return m < 10 ? '0' + m : m;
    },
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      return y + '-' + this.add0(m) + '-' + this.add0(d);
    },
    getIdArr(o) {
      this.save_data_id = [];
      clearTimeout(this.tiemID);
      this.tiemID = setTimeout(() => {
        o.forEach((key) => {
          this.save_data_id.push(this.tableData.items[key].purchase_invoice_id);
        });
      }, 500);
    },

    init() {
      if (this.CACHE.ifCache('SaaSPuchaseInvoiceFile')) {
        this.tableSubmit = this.CACHE.getCache(
          this.currentUser.uid,
          'SaaSPuchaseInvoiceFile'
        );
        Object.keys(this.searchConfiguration).forEach((key) => {
          if (this.searchConfiguration[key].type == 'checked-all') {
            if (
              this.tableSubmit[key].length ==
              this.searchConfiguration[key].options.length
            ) {
              this.searchConfiguration[key].allSelected = true;
            } else {
              this.searchConfiguration[key].allSelected = false;
            }
          }
        });
        this.$refs.TAdvancedSearch.visible =
          this.CACHE.getVisible('SaaSPuchaseInvoiceFile');
      } else {
        this.tableSubmit = this.initialTableSubmit;
      }
    }
  },

  computed: {
    ...mapState({
      baseConfig: (state) => state.baseconfig.baseconfig
    }),
    ...mapGetters(['currentUser']) // 获取用户名
  },

  mounted() {
    this.init();
    this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
      if (collapseId == 'advanced-search') {
        this.CACHE.addVisible('SaaSPuchaseInvoiceFile', isJustShown);
      }
    });
    if (this.options.sales_channel.length == 0) {
      if (Object.keys(this.baseConfig).length == 0) {
        apiBus.purchase
          .getBaseConfig()
          .then((data) => {
            console.log('getBaseConfig数据缓存vuex');
            this.options.sales_channel = data.data.data.sales_channel;
            this.$store.dispatch(RENEW_BASE_CONFIG, data.data.data);
            // 给Order Type赋值
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        console.log('有数据');
        this.options.sales_channel = this.baseConfig.sales_channel;
      }
    }
  },
  watch: {
    save_data(o) {
      this.getIdArr(o);
    }
  }
};
</script>
<style scoped></style>
