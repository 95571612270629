<template>
  <div>
    <b-form-file
      v-model="model"
      :disabled="state == 'uploading'"
      :accept="accept"
      placeholder="Upload"
      class="exhibit-input upload-invoice mr-2"
      multiple
      @input="input($event)"
    >
      <template slot="file-name" slot-scope="{}">
        <div>
          Upload
          <b-icon
            v-if="state == 'uploading'"
            class="counterclockwise"
            icon="arrow-counterclockwise"
            animation="spin-reverse"
            font-scale="1.2"
          >
          </b-icon>
        </div>
      </template>
    </b-form-file>
  </div>
</template>

<script>
// import JwtService from '@/common/services/jwt';
// import { PROMPT_STATUS } from '@/store/modules/statusprompt/actions';
export default {
  name: 'Upload',
  data() {
    return {
      model: null,
      state: ''
    };
  },
  props: ['accept', 'input'],
  components: {},
  methods: {},

  computed: {},
  mounted() {
    // console.log(JwtService.getToken());
  }
};
</script>
<style scoped></style>
